import Layout from "../components/layout/Layout";
import DynamicComponent from "../components/DynamicComponent";
import Storyblok, { defaultStoryblokOptions, getComponentProps, useStoryblok } from "../utils/storyblok";
import { GetStaticPaths } from "next";
import { NextSeo } from "next-seo";
import { getAllPageData } from "../utils/ppmApi";
import { useEffect, useState } from "react";
import { useCheckEmptyGlobalState } from "../utils/hooks";

interface ComponentProps extends AllPageProps {}

export default function Page(props: ComponentProps):JSX.Element {
  const [globalState, setGlobalState] = useState<GlobalState>(props.globalState);
  const [story, setStory] = useStoryblok(props.story, props.locale);

  useCheckEmptyGlobalState(props);

  return ( 
    <Layout locale={props.locale} locales={props.locales} defaultLocale={props.defaultLocale} globalState={globalState} setGlobalState={setGlobalState}>
      <NextSeo
        title={story.content.metatags?.title}
        description={story.content.metatags?.description}
      />
      <DynamicComponent blok={story.content} globalState={globalState} setGlobalState={setGlobalState} />
    </Layout>
  );
}

export async function getStaticProps({
  locale,
  locales,
  defaultLocale,
  params,
}) {
  try {
    // get storyblok data
    let slug = params.slug ? params.slug.join("/") : "/";
  
    const sbParams = {
      ...defaultStoryblokOptions,
      language: locale,
    };
  
    const pageData = await Storyblok.get(`cdn/stories/${process.env.NEXT_PUBLIC_STORYBLOK_PAGES_URL}/${slug}`, sbParams);
  
    if (!pageData.data.story) throw new Error("No story found");
  
    const componentSpecificData = await getComponentProps(pageData, locale)

    // get other page data from storyblok/ppmapi
    const allPageData = await getAllPageData(locale, defaultLocale);

    return {
      props: {
        story: pageData.data ? pageData.data.story : false,
        locale,
        locales,
        defaultLocale,
        globalState: { ...allPageData, componentSpecificData: {...componentSpecificData} },
        slug: slug
      },
      revalidate: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? 3600 : 1, // rebuild in 1 hour for production,  1 second for staging
    };
  
  } catch (e) {
    console.error("error in getStaticProps", e)
    return {
      notFound: true,
      revalidate: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? 3600 : 1, // rebuild in 1 hour for production,  1 second for staging
    };
  }
}

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const { data } = await Storyblok.get(`cdn/links/?starts_with=${process.env.NEXT_PUBLIC_STORYBLOK_PAGES_URL}/`);

  const paths = [];

  Object.keys(data.links).forEach((linkKey) => {
    if (data.links[linkKey].is_folder) {
      return;
    }

    // these pages/folders have their own templates, exclude them from the paths this template covers
    const excludeList = [
      `/mission-trips`,
      `/365`,
      `/response`,
      `/mission-trip-resources`,
      `/employment`,
      `/response`,
      `/365`,
      `/landing`,
      `/terms-and-conditions`,
      `/trip-fundraising`,
      `/blog`,
      `/mission-trip-journals`,
      `/thanks-for-serving`
    ].filter(page => {
      return data.links[linkKey].slug.indexOf(`${process.env.NEXT_PUBLIC_STORYBLOK_PAGES_URL}${page}`) === 0
    });

    if (excludeList.length > 0) return;

    const regex = /^usa\/pages\/(.*)/;
    const slug = data.links[linkKey].slug.replace(regex, "$1");

    let splittedSlug = slug.split("/");

    if (slug === "/") splittedSlug = false;

    // create additional languages
    for (const locale of locales) {
      paths.push({ params: { slug: splittedSlug }, locale });
    }
  });

  return {
    paths: paths,
    fallback: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? false : "blocking",
  };
}
