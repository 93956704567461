import { Box, Divider, Stack, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import Script from "next/script";
import { getRealLink } from "../../../utils/misc";

const FooterUsefulLinks = (props: {globalState: GlobalState}): JSX.Element => {
  
  return (
    <Box>
      <Stack mb="5">
        <Text
          as="p"
          mb="0"
          fontWeight={"bold"}
          color="brand.darkgray.200"
        >
          USEFUL LINKS
        </Text>
        
        <Divider w="150px" borderColor="brand.darkgray.400" />

        {
          props.globalState.footerLinks.footerUsefulLinks
            .sort((a, b) => a.text.trim().localeCompare(b.text.trim()))
            .map((link: FooterUsefulLinkItem, index) => {
              const {cachedUrl, url} = link.link;

              const [linkHref] = getRealLink({cached_url: cachedUrl, url: url}, false);

              return (
                <Link
                  href={linkHref}
                  key={index}
                  passHref
                  target={link.isExternalLink ? '_blank' : '_self'}
                  legacyBehavior>
                  <Text as="a" target={linkHref.indexOf('http') === 0 ? '_blank' : '_self'}>
                    <FontAwesomeIcon icon={'chevron-right'} style={{marginRight: '5px'}} />
                    {link.text}
                    {linkHref.indexOf('http') === 0 && <FontAwesomeIcon icon={'external-link'} style={{ marginLeft: '8px' }} />}
                  </Text>
                </Link>
              );
          })
        }
      
      </Stack>
    </Box>
  );
}

export default FooterUsefulLinks;