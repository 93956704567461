import { Box, Button, Center, Flex, Stack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/legacy/image";
import Link from "next/link";
import { ContactUsStack } from "../navigation/subnavs/ContactUsSubNav";

const FooterAddress = ():JSX.Element => {
  return (
    <Flex direction="column" justifyContent="space-between">
      <Center w="full">
        <Image src={'/images/praying-pelican-logo.png'} alt="Praying Pelican Missions Logo" width={2355 / 15} height={988 / 15}></Image>
      </Center>
      
      <ContactUsStack textColor={'brand.darkgray.200'} />

      <Center w="full">
        <Link href={'https://account.ppm.org/'} passHref legacyBehavior>
          <Button
            as="a"
            sx={{ '&:hover': { textDecoration: 'none' } }}
            size={'md'}
            colorScheme={'brand.green'}
            variant={'solid'}
            fontWeight={'bold'}
            lineHeight={0}
            leftIcon={<FontAwesomeIcon icon={'user'} />}
            target={'_blank'}
            w="3xs"
          >
            Pelican Account
          </Button>
        </Link>
      </Center>
    </Flex>
  );
}

export default FooterAddress;